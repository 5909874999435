<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="back"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <div slot="right">
        <div class="header-menu">
          <div class="icon" @click="onSearch">
            <van-icon name="search" />
          </div>
          <div class="icon" @click="onAdd">
            <van-icon name="plus" />
          </div>
        </div>
      </div>
    </fb-header>
    <join-organization v-if="!this.organizationId" />
    <!-- 筛选栏 -->
    <van-popup v-model="filterVisible" position="right" get-container="body">
      <div class="filter-right" :style="`height:${clientHeight}px`">
        <div class="filter-title">筛选</div>
        <div class="filter-box" v-for="(item, i) in filterList" :key="i">
          <!-- <van-checkbox
            v-model="option.isSelect"
            shape="square"
            @click="onRadio(i, index)"
            v-for="(option, index) in item.options"
            :key="index"
            >{{ option.text }}</van-checkbox
          > -->
          <van-radio-group v-model="item.radio" ref="radioGroup">
            <van-radio
              ref="radio"
              :name="option.value"
              shape="square"
              v-for="(option, index) in item.options"
              :key="index"
              >{{ option.text }}</van-radio
            >
          </van-radio-group>
        </div>
        <div class="filter-btn">
          <button class="btn" @click="reset">重置</button>
          <button class="btn" @click="sure">确定</button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="searchVisible" position="top" get-container="body">
      <van-field
        left-icon="search"
        v-model="key"
        placeholder="检索案件名称、案号"
        @input="searchInput"
        ref="inputsearch"
      />
    </van-popup>
    <div class="oa-finance">
      <div class="oa-finance-tab">
        <div
          class="oa-finance-tab-item"
          v-for="(item, i) in tabList"
          :key="i"
          @click="onTab(item)"
        >
          <span
            :style="item.value === tab ? 'color:#0066FF' : 'color:#000000'"
            >{{ item.text }}</span
          >
          <van-icon name="arrow-down" color="#CCCCCC" size="14px" />
        </div>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="!isRequest"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <!-- 收支列表 -->
          <div class="oa-finance-receive-list" v-if="tab === '1'">
            <div
              class="oa-finance-receive"
              v-for="(item, i) in receiveList"
              :key="i"
              @click="jumpReceive(item, i)"
            >
              <div class="receive-title">
                <div>{{ item.stage }} {{ item.typeText }}</div>
                <div>{{ item.amount }}元</div>
                <div v-if="item.payee === null || item.payee === ''">
                  收款人：无
                </div>
                <div v-else>收款人：{{ item.payee }}</div>
              </div>
              <div class="receive-case-name">
                <div>{{ item.legalCaseName }}</div>
              </div>
            </div>
          </div>
          <!-- 报销列表 -->
          <div class="oa-case-list" v-if="tab === '2'">
            <div
              class="oa-case"
              v-for="(item, index) in reportList"
              :key="index"
              @click="jump(item, index)"
            >
              <div class="oa-case-header">
                <div class="oa-case-money">金额：{{ item.money }}元</div>
                <div class="oa-case-userInfo">
                  <img :src="item.avatarUrl" alt="" />
                  <div>{{ item.applicantName }}</div>
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNSUBMENT'"
                >
                  未提交
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNAUDITED'"
                >
                  待审核
                </div>
                <div class="oa-case-status" v-if="item.progress === 'APPROVED'">
                  审核通过
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'CERTIFIED_FAILED'"
                >
                  审核不通过
                </div>
              </div>
              <div class="oa-case-category">
                <div>{{ item.reimType }}</div>
                <div>{{ categoryFmt(item.category) }}</div>
              </div>
              <div class="oa-case-bottom">
                <div class="oa-case-time">申请时间：{{ item.useTime }}</div>
                <div class="oa-case-long">{{ item.useTimeText }}</div>
                <div class="oa-case-btn">
                  <button
                    v-for="(btnItem, btnIndex) in item.btnList"
                    :key="btnIndex"
                    @click.stop="btnClick(btnItem, item, index)"
                  >
                    {{ btnItem }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- 开票列表 -->
          <div class="oa-case-list" v-if="tab === '3'">
            <div
              class="oa-case"
              v-for="(item, index) in invoiceList"
              :key="index"
              @click="jump(item, index)"
            >
              <div class="oa-case-header">
                <div class="oa-case-money">金额：{{ item.money }}元</div>
                <div class="oa-case-userInfo">
                  <img :src="item.avatarUrl" alt="" />
                  <div>{{ item.applicantName }}</div>
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNSUBMENT'"
                >
                  未提交
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNAUDITED'"
                >
                  待审核
                </div>
                <div class="oa-case-status" v-if="item.progress === 'APPROVED'">
                  审核通过
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'CERTIFIED_FAILED'"
                >
                  审核不通过
                </div>
              </div>
              <div class="oa-case-center">
                <div>{{ item.caseName }}</div>
              </div>
              <div class="oa-case-bottom">
                <div class="oa-case-time">申请时间：{{ item.useTime }}</div>
                <div class="oa-case-long">{{ item.useTimeText }}</div>
                <div class="oa-case-btn">
                  <button
                    v-for="(btnItem, btnIndex) in item.btnList"
                    :key="btnIndex"
                    @click.stop="btnClick(btnItem, item, index)"
                  >
                    {{ btnItem }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- 借款列表 -->
          <div class="oa-case-list" v-if="tab === '4'">
            <div
              class="oa-case"
              v-for="(item, index) in loanList"
              :key="index"
              @click="jump(item, index)"
            >
              <div class="oa-case-header">
                <div class="oa-case-money">
                  金额：{{ item.amountOfMoney }}元
                </div>
                <div class="oa-case-userInfo">
                  <img :src="item.avatarUrl" alt="" />
                  <div>{{ item.applicantName }}</div>
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNSUBMENT'"
                >
                  未提交
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNAUDITED'"
                >
                  待审核
                </div>
                <div class="oa-case-status" v-if="item.progress === 'APPROVED'">
                  审核通过
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'CERTIFIED_FAILED'"
                >
                  审核不通过
                </div>
              </div>
              <div class="oa-case-category">
                <div>事由：{{ item.cause }}</div>
              </div>
              <div class="oa-case-bottom">
                <div class="oa-case-time">申请时间：{{ item.useTime }}</div>
                <div class="oa-case-long">{{ item.useTimeText }}</div>
                <div class="oa-case-btn">
                  <button
                    v-for="(btnItem, btnIndex) in item.btnList"
                    :key="btnIndex"
                    @click.stop="btnClick(btnItem, item, index)"
                  >
                    {{ btnItem }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- 收款列表 -->
          <div class="oa-finance-receive-list" v-if="tab === '5'">
            <div
              class="oa-finance-receive"
              v-for="(item, index) in incomeList"
              :key="index"
              @click="jump(item, index)"
            >
              <div class="receive-title">
                <div>{{ item.applyTime }} {{ item.feeType }}</div>
                <div>{{ item.money }}元</div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNSUBMENT'"
                >
                  未提交
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNAUDITED'"
                >
                  待审核
                </div>
                <div class="oa-case-status" v-if="item.progress === 'APPROVED'">
                  审核通过
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'CERTIFIED_FAILED'"
                >
                  审核不通过
                </div>
              </div>
              <div class="oa-income">
                <div class="oa-income-casename">{{ item.caseName }}</div>
                <div class="oa-case-btn">
                  <button
                    v-for="(btnItem, btnIndex) in item.btnList"
                    :key="btnIndex"
                    @click.stop="btnClick(btnItem, item, index)"
                  >
                    {{ btnItem }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import { mapState, mapActions, mapMutations } from 'vuex'
import JoinOrganization from '@/components/join-organization'

export default {
  name: 'oa-finance',
  mixins: [goBackMixin],
  components: {
    JoinOrganization
  },
  data() {
    return {
      // 收支筛选条件列表
      receiveFilterList: [
        {
          radio: '',
          type: 'tyep',
          options: [
            {
              text: '收入',
              value: 'EARNING',
              isSelect: false
            },
            {
              text: '支出',
              value: 'EXPENSE',
              isSelect: false
            }
          ]
        }
      ],
      // 借款筛选列表
      loanFilterList: [
        {
          radio: '',
          type: 'progress',
          options: [
            {
              text: '全部',
              value: '',
              isSelect: false
            },
            {
              text: '待审核',
              value: 'UNAUDITED',
              isSelect: true
            },
            {
              text: '审核通过',
              value: 'APPROVED',
              isSelect: false
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED',
              isSelect: false
            }
          ]
        },
        {
          radio: 'APPLICANT',
          type: 'userType',
          options: [
            {
              text: '我申请的',
              value: 'APPLICANT',
              isSelect: true
            },
            {
              text: '我审批的',
              value: 'APPROVER',
              isSelect: false
            },
            {
              text: '抄送我的',
              value: 'RECIPIENT',
              isSelect: false
            }
          ]
        }
      ],
      // 开票筛选列表
      invoiceFilterList: [
        {
          radio: '',
          type: 'progress',
          options: [
            {
              text: '全部',
              value: '',
              isSelect: false
            },
            {
              text: '待审核',
              value: 'UNAUDITED',
              isSelect: true
            },
            {
              text: '审核通过',
              value: 'APPROVED',
              isSelect: false
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED',
              isSelect: false
            }
          ]
        },
        {
          radio: 'APPLICANT',
          type: 'userType',
          options: [
            {
              text: '我申请的',
              value: 'APPLICANT',
              isSelect: true
            },
            {
              text: '我审批的',
              value: 'APPROVER',
              isSelect: false
            },
            {
              text: '抄送我的',
              value: 'RECIPIENT',
              isSelect: false
            }
          ]
        },
        {
          radio: '',
          type: 'type',
          options: [
            {
              text: '增值税普通发票',
              value: 'VALUE_ADDED_TAX',
              isSelect: false
            },
            {
              text: '增值税专用发票',
              value: 'VAT_SPECIAL',
              isSelect: false
            }
          ]
        }
      ],
      // 借款筛选列表
      reportFilterList: [
        {
          radio: '',
          type: 'progress',
          options: [
            {
              text: '全部',
              value: '',
              isSelect: false
            },
            {
              text: '待审核',
              value: 'UNAUDITED',
              isSelect: true
            },
            {
              text: '审核通过',
              value: 'APPROVED',
              isSelect: false
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED',
              isSelect: false
            }
          ]
        },
        {
          radio: 'APPLICANT',
          type: 'userType',
          options: [
            {
              text: '我申请的',
              value: 'APPLICANT',
              isSelect: true
            },
            {
              text: '我审批的',
              value: 'APPROVER',
              isSelect: false
            },
            {
              text: '抄送我的',
              value: 'RECIPIENT',
              isSelect: false
            }
          ]
        },
        {
          radio: '',
          type: 'category',
          options: [
            {
              text: '合作费',
              value: 'LAWFIRM',
              isSelect: false
            },
            {
              text: '办案费',
              value: 'CASE',
              isSelect: false
            }
          ]
        }
      ],
      // 收款筛选列表
      incomeFilterList: [
        {
          radio: null,
          type: 'progress',
          options: [
            {
              text: '全部',
              value: null,
              isSelect: false
            },
            {
              text: '待审核',
              value: 'UNAUDITED',
              isSelect: true
            },
            {
              text: '审核通过',
              value: 'APPROVED',
              isSelect: false
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED',
              isSelect: false
            }
          ]
        },
        {
          radio: 'APPLICANT',
          type: 'userType',
          options: [
            {
              text: '我申请的',
              value: 'APPLICANT',
              isSelect: true
            },
            {
              text: '我审批的',
              value: 'APPROVER',
              isSelect: false
            },
            {
              text: '抄送我的',
              value: 'RECIPIENT',
              isSelect: false
            }
          ]
        }
      ],
      filterList: [],
      clientHeight: document.body.clientHeight,
      title: '财务管理',
      tabList: [
        {
          text: '收支',
          value: '1'
        },
        {
          text: '报销',
          value: '2'
        },
        {
          text: '开票',
          value: '3'
        },
        {
          text: '借款',
          value: '4'
        },
        {
          text: '收款',
          value: '5'
        }
      ],
      filterVisible: false, // 筛选按钮
      searchVisible: false, // 搜索弹框
      tab: '1', // 当前选择的tab
      key: '',
      startTime: '',
      endTime: '',
      progress: '',
      page: 0,
      size: 15,
      itemList: [], // 收支类型列表
      receiveList: [], // 收支列表
      isRequest: true, // 是否可以继续请求
      loading: true, // 是否处于加载状态
      refreshing: false, // 是否处于下拉刷新状态
      invoiceList: [], // 开票列表
      loanList: [], // 借款列表
      reportList: [], // 报销列表
      incomeList: [] // 收款列表
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo']),
    ...mapState('oa', ['oaTab', 'filterKV'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('oa', ['setOaTab', 'setFilters']),
    // onRadio(index, itemIndex) {
    //   let options = this.filterList[index].options
    //   for (var i = 0; i < options.length; i++) {
    //     options[i].isSelect = false
    //     if (options[itemIndex].value === this.filterList[index].radio) {
    //       this.filterList[index].radio = ''
    //     } else {
    //       options[itemIndex].isSelect = true
    //       this.filterList[index].radio = options[itemIndex].value
    //     }
    //   }
    //   this.filterList[index].options = options
    //   for (var j = 0; j < options.length; j++) {
    //     if (options[j].isSelect) {
    //       this.filterList[index].radio = options[i].value
    //       break
    //     }
    //   }
    //   console.log(this.filterList)
    // },
    back() {
      this.setFilters(null)
      this.backToNative()
    },
    // 重置
    reset() {
      if (this.tab === '1') {
        this.filterList = this.deepCopy(this.receiveFilterList)
      } else if (this.tab === '2') {
        this.filterList = this.deepCopy(this.reportFilterList)
      } else if (this.tab === '3') {
        this.filterList = this.deepCopy(this.invoiceFilterList)
      } else if (this.tab === '4') {
        this.filterList = this.deepCopy(this.loanFilterList)
      } else if (this.tab === '5') {
        this.filterList = this.deepCopy(this.incomeFilterList)
      }
      this.openFilter()
      this.onRefresh()
    },
    // 确定
    sure() {
      this.openFilter()
      this.onRefresh()
    },
    // 报销方式格式化
    categoryFmt(code) {
      const dict = {
        LAWFIRM: '律所报销',
        CASE: '案件报销'
      }
      return dict[code]
    },
    // 点击添加事件
    onAdd() {
      this.setFiltersInPage()
      if (this.tab === '1') {
        this.$router.push({
          name: 'oa-addReceive',
          query: {
            receive: '',
            isAdd: 0 // 0表示新增
          }
        })
      } else if (this.tab === '2') {
        this.$router.push({
          name: 'oa-addReport',
          query: {
            report: '',
            isAdd: 0 // 0表示新增
          }
        })
      } else if (this.tab === '3') {
        this.$router.push({
          name: 'oa-addInvoice',
          query: {
            invoice: '',
            isAdd: 0 // 0表示新增
          }
        })
      } else if (this.tab === '4') {
        this.$router.push({
          name: 'oa-addLoan',
          query: {
            loan: '',
            isAdd: 0 // 0表示新增
          }
        })
      } else if (this.tab === '5') {
        this.$router.push({
          name: 'oa-addIncome',
          query: {
            income: '',
            isAdd: 0 // 0表示新增
          }
        })
      }
    },
    // 点击搜索事件
    onSearch() {
      this.searchVisible = true
      setTimeout(() => {
        this.$refs.inputsearch.focus()
      }, 100)
    },
    // tabbar点击事件
    onTab(item) {
      if (item.value === this.tab) {
        return this.openFilter()
      }
      this.tab = item.value
      this.page = 0
      this.isRequest = true
      if (item.value === '1') {
        this.filterList = this.deepCopy(this.receiveFilterList)
        this.receiveList = []
        this.onRefresh()
      } else if (item.value === '2') {
        if (!this.organizationId) {
          return this.$bus.$emit('join-org', { show: true })
        }
        this.filterList = this.deepCopy(this.reportFilterList)
        this.reportList = []
        this.onRefresh()
      } else if (item.value === '3') {
        if (!this.organizationId) {
          return this.$bus.$emit('join-org', { show: true })
        }
        this.filterList = this.deepCopy(this.invoiceFilterList)
        this.invoiceList = []
        this.onRefresh()
      } else if (item.value === '4') {
        if (!this.organizationId) {
          return this.$bus.$emit('join-org', { show: true })
        }
        this.filterList = this.deepCopy(this.loanFilterList)
        this.loanList = []
        this.onRefresh()
      } else if (item.value === '5') {
        if (!this.organizationId) {
          return this.$bus.$emit('join-org', { show: true })
        }
        this.filterList = this.deepCopy(this.incomeFilterList)
        this.incomeList = []
        this.onRefresh()
      }
    },

    // 数组深拷贝
    deepCopy(o) {
      // var [...arr2] = o
      // return arr2
      var n
      if (o instanceof Array) {
        n = []
        for (var j = 0; j < o.length; ++j) {
          n[j] = this.deepCopy(o[j])
        }
        return n
      } else if (o instanceof Object) {
        n = {}
        for (var i in o) {
          n[i] = this.deepCopy(o[i])
        }
        return n
      } else {
        return o
      }
    },

    // 展开筛选栏
    openFilter() {
      this.filterVisible = !this.filterVisible
    },

    // 获取收支类型
    async getCategory() {
      const res = await this.$axios.get(
        `${this.$base}/management/finance/category`
      )
      if (res.data.data) {
        res.data.data.forEach((item, index) => {
          this.itemList.push({
            value: item.type,
            label: item.name
          })
        })
        console.log(this.itemList)
      }
    },

    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(parseInt(cjsj)) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // var h =
      //   ' ' +(date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      // var m =
      //   date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D
    },
    // 获取借款列表
    getLoanList() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        userId: this.userInfo.id,
        userType: this.filterList[1].radio,
        progress: this.filterList[0].radio,
        page: this.page,
        size: this.size
      }
      this.$axios
        .get(`${this.$base}/management/borrow/record`, {
          params: params
        })
        .then(res => {
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              this.loanList = res.data.data.content
              this.isRequest = false
            } else {
              if (res.data.data.content.length > 0) {
                for (var i = 0; i < res.data.data.content.length; i++) {
                  let avatarUrl = ''
                  let applicantName = ''
                  res.data.data.content[i].approveRecordUsers.map((item, i) => {
                    if (item.type === 'APPLICANT') {
                      avatarUrl = item.user.avatarUrl
                      applicantName = item.user.nickName
                    }
                  })
                  res.data.data.content[i].avatarUrl = avatarUrl
                  res.data.data.content[i].applicantName = applicantName
                  // 申请时间
                  res.data.data.content[i].useTime = this.timestampToTime2(
                    res.data.data.content[i].createTime
                  )
                  res.data.data.content[i].useTimeText = this.getDateDiff(
                    res.data.data.content[i].createTime
                  )
                  switch (res.data.data.content[i].progress) {
                    case 'UNSUBMENT':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                    case 'UNAUDITED':
                      for (
                        var j = 0;
                        j < res.data.data.content[i].approveRecordUsers.length;
                        j++
                      ) {
                        if (
                          res.data.data.content[i].approveRecordUsers[j]
                            .type === 'APPROVER'
                        ) {
                          if (
                            res.data.data.content[i].approveRecordUsers[j].user
                              .id === this.userInfo.id
                          ) {
                            res.data.data.content[i].btnList = [
                              '批准',
                              '驳回'
                            ]
                            break
                          } else {
                            res.data.data.content[i].btnList = []
                          }
                        }
                      }
                      break
                    case 'APPROVED':
                      res.data.data.content[i].btnList = []
                      break
                    case 'CERTIFIED_FAILED':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                  }
                }
                this.loanList = this.loanList.concat(res.data.data.content)
              }
              console.log(this.loanList)
              if (this.page + 1 >= res.data.data.totalPages) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          } else {
            this.isRequest = false
          }
          this.loading = false
          this.refreshing = false
          this.$toast.clear()
        })
    },
    // 获取收款列表
    getIncomeList() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        userId: this.userInfo.id,
        userType: this.filterList[1].radio,
        progress: this.filterList[0].radio,
        page: this.page,
        size: this.size
      }
      this.$axios
        .post(`${this.$base}/management/draw/all`, params)
        .then(res => {
          if (res.data.data) {
            const content = res.data.data.records
            if (res.data.data.total === 0) {
              // 无数据显示缺省页面
              this.loanList = content
              this.isRequest = false
            } else {
              if (content.length > 0) {
                for (var i = 0; i < content.length; i++) {
                  let avatarUrl = ''
                  let applicantName = ''
                  content[i].users.map((item, i) => {
                    if (item.type === 'APPLICANT') {
                      avatarUrl = item.userName
                      applicantName = item.userName
                    }
                  })
                  content[i].avatarUrl = avatarUrl
                  content[i].applicantName = applicantName
                  switch (content[i].progress) {
                    case 'UNSUBMENT':
                      content[i].btnList = ['申请', '删除']
                      break
                    case 'UNAUDITED':
                      for (var j = 0; j < content[i].users.length; j++) {
                        if (content[i].users[j].type === 'APPROVER') {
                          if (content[i].users[j].userId === this.userInfo.id) {
                            content[i].btnList = ['批准', '驳回']
                            break
                          } else {
                            content[i].btnList = []
                          }
                        }
                      }
                      break
                    case 'APPROVED':
                      content[i].btnList = []
                      break
                    case 'CERTIFIED_FAILED':
                      content[i].btnList = ['申请', '删除']
                      break
                  }
                }
                this.incomeList = this.incomeList.concat(content)
              }
              console.log(this.incomeList)
              if (this.page + 1 >= res.data.data.total) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          } else {
            this.isRequest = false
          }
          this.loading = false
          this.refreshing = false
          this.$toast.clear()
        })
    },
    // 获取报销列表
    getReportList() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        userId: this.userInfo.id,
        userType: this.filterList[1].radio,
        progress: this.filterList[0].radio,
        category: this.filterList[2].radio,
        page: this.page,
        size: this.size
      }
      this.$axios
        .get(`${this.$base}/management/reimbursement/record`, {
          params: params
        })
        .then(res => {
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              this.reportList = res.data.data.content
              this.isRequest = false
            } else {
              if (res.data.data.content.length > 0) {
                for (var i = 0; i < res.data.data.content.length; i++) {
                  let avatarUrl = ''
                  let applicantName = ''
                  res.data.data.content[i].approveRecordUsers.map((item, i) => {
                    if (item.type === 'APPLICANT') {
                      avatarUrl = item.user.avatarUrl
                      applicantName = item.user.nickName
                    }
                  })
                  res.data.data.content[i].avatarUrl = avatarUrl
                  res.data.data.content[i].applicantName = applicantName
                  // 申请时间
                  res.data.data.content[i].useTime = this.timestampToTime2(
                    res.data.data.content[i].createTime
                  )
                  res.data.data.content[i].useTimeText = this.getDateDiff(
                    res.data.data.content[i].createTime
                  )
                  switch (res.data.data.content[i].progress) {
                    case 'UNSUBMENT':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                    case 'UNAUDITED':
                      for (
                        var j = 0;
                        j < res.data.data.content[i].approveRecordUsers.length;
                        j++
                      ) {
                        if (
                          res.data.data.content[i].approveRecordUsers[j]
                            .type === 'APPROVER'
                        ) {
                          if (
                            res.data.data.content[i].approveRecordUsers[j].user
                              .id === this.userInfo.id
                          ) {
                            res.data.data.content[i].btnList = [
                              '批准',
                              '驳回'
                            ]
                            break
                          } else {
                            res.data.data.content[i].btnList = []
                          }
                        }
                      }
                      break
                    case 'APPROVED':
                      res.data.data.content[i].btnList = []
                      break
                    case 'CERTIFIED_FAILED':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                  }
                }
                this.reportList = this.reportList.concat(res.data.data.content)
              }
              console.log(this.reportList)

              if (this.page + 1 >= res.data.data.totalPages) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          } else {
            this.isRequest = false
          }
          this.loading = false
          this.refreshing = false
          this.$toast.clear()
        })
    },
    // 获取收支列表
    getReceiveList() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        key: this.key,
        startTime: this.startTime,
        endTime: this.endTime,
        page: this.page,
        size: this.size,
        type: this.filterList[0].radio
      }
      this.$axios
        .get(
          `${this.$base}/management/user/${this.userInfo.id}/finance/records`,
          {
            params: params
          }
        )
        .then(res => {
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              this.receiveList = res.data.data.content
              this.isRequest = false
            } else {
              if (res.data.data.content.length > 0) {
                for (var i = 0; i < res.data.data.content.length; i++) {
                  res.data.data.content[i].stage = this.timestampToTime(
                    res.data.data.content[i].stage
                  )
                  for (var j = 0; j < this.itemList.length; j++) {
                    if (
                      res.data.data.content[i].type === this.itemList[j].value
                    ) {
                      res.data.data.content[i].typeText = this.itemList[j].label
                      break
                    }
                  }
                }
                this.receiveList = this.receiveList.concat(
                  res.data.data.content
                )
              }
              console.log(this.receiveList)

              if (this.page + 1 >= res.data.data.totalPages) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          } else {
            this.isRequest = false
          }
          this.loading = false
          this.refreshing = false
          this.$toast.clear()
        })
    },

    // 跳转详情
    jumpReceive(item, i) {
      this.setFiltersInPage()
      this.$router.push({
        name: 'oa-addReceive',
        query: {
          receive: item.legalCaseId,
          isAdd: 1 // 0表示新增
        }
      })
    },

    // 获取开票列表
    getInvoice() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        userId: this.userInfo.id,
        keyword: this.key,
        startTime: this.startTime,
        endTime: this.endTime,
        page: this.page,
        progress: this.filterList[0].radio,
        userType: this.filterList[1].radio,
        type: this.filterList[2].radio
      }
      this.$axios
        .get(`${this.$base}/management/invoice/record`, {
          params: params
        })
        .then(res => {
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              this.invoiceList = res.data.data.content
              this.isRequest = false
            } else {
              if (res.data.data.content.length > 0) {
                for (var i = 0; i < res.data.data.content.length; i++) {
                  let avatarUrl = ''
                  let applicantName = ''
                  res.data.data.content[i].approveRecordUsers.map((item, i) => {
                    if (item.type === 'APPLICANT') {
                      avatarUrl = item.user.avatarUrl
                      applicantName = item.user.nickName
                    }
                  })
                  res.data.data.content[i].avatarUrl = avatarUrl
                  res.data.data.content[i].applicantName = applicantName
                  // 申请时间
                  res.data.data.content[i].useTime = this.timestampToTime2(
                    res.data.data.content[i].createTime
                  )
                  res.data.data.content[i].useTimeText = this.getDateDiff(
                    res.data.data.content[i].createTime
                  )
                  switch (res.data.data.content[i].progress) {
                    case 'UNSUBMENT':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                    case 'UNAUDITED':
                      for (
                        var j = 0;
                        j < res.data.data.content[i].approveRecordUsers.length;
                        j++
                      ) {
                        if (
                          res.data.data.content[i].approveRecordUsers[j]
                            .type === 'APPROVER'
                        ) {
                          if (
                            res.data.data.content[i].approveRecordUsers[j].user
                              .id === this.userInfo.id
                          ) {
                            res.data.data.content[i].btnList = [
                              '批准',
                              '驳回'
                            ]
                            break
                          } else {
                            res.data.data.content[i].btnList = []
                          }
                        }
                      }
                      break
                    case 'APPROVED':
                      res.data.data.content[i].btnList = []
                      break
                    case 'CERTIFIED_FAILED':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                  }
                }
                this.invoiceList = this.invoiceList.concat(
                  res.data.data.content
                )
              }
              if (this.page + 1 >= res.data.data.totalPages) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          } else {
            this.isRequest = false
          }
          this.loading = false
          this.refreshing = false
          this.$toast.clear()
        })
    },
    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天前'
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时前'
      } else {
        result = '' + parseInt(minC) + '分钟前'
      }
      return result
    },

    // 时间戳转换成时间
    timestampToTime2(cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h =
        (date.getHours() + 1 < 10
          ? '0' + (date.getHours() + 1)
          : date.getHours() + 1) + ':'
      var m =
        date.getMinutes() + 1 < 10
          ? '0' + (date.getMinutes() + 1)
          : date.getMinutes() + 1
      return Y + M + D + h + m
    },

    /**
     * 按钮点击事件
     * name 操作名称
     * item 数据内容
     * index 第几条数据
     */
    btnClick(name, item, index) {
      if (name === '删除') {
        this.$dialog
          .confirm({
            title: '提示',
            message: '删除不可恢复！确定删除吗？'
          })
          .then(async () => {
            // on confirm
            let url = ''
            if (this.tab === '3') {
              url = `${this.$base}/management/invoice/record/delete/${item.id}`
            } else if (this.tab === '2') {
              url = `${this.$base}/management/reimbursement/record/delete/${
                item.id
              }`
            } else if (this.tab === '4') {
              url = `${this.$base}/management/borrow/record/delete/${item.id}`
            } else if (this.tab === '5') {
              url = `${this.$base}/management/draw/delete`
            }
            let response = null
            if (this.tab === '5') {
              console.log(item.id)
              const params = { id: item.id }
              response = await this.$axios.post(url, { data: params })
            } else {
              response = await this.$axios.post(url)
            }
            const { code } = response.data
            if (code === 200) {
              this.$toast.success('删除成功')
              if (this.tab === '3') {
                this.invoiceList.splice(index, 1)
              } else if (this.tab === '2') {
                this.reportList.splice(index, 1)
              } else if (this.tab === '4') {
                this.loanList.splice(index, 1)
              } else if (this.tab === '5') {
                this.incomeList.splice(index, 1)
              }
            }
          })
          .catch(() => {
            // on cancel
          })
      } else if (name === '批准' || name === '驳回') {
        var obj = JSON.parse(JSON.stringify(item))
        obj.progress = name === '批准' ? 'APPROVED' : 'CERTIFIED_FAILED'
        obj.useTime = new Date(item.useTime).getTime()
        let url = ''
        if (this.tab === '3') {
          url = `${this.$base}/management/invoice/record/${item.id}/progress`
        } else if (this.tab === '2') {
          url = `${this.$base}/management/reimbursement/record/${
            item.id
          }/progress?progress=${obj.progress}`
        } else if (this.tab === '4') {
          url = `${this.$base}/management/borrow/record/${item.id}/progress`
        } else if (this.tab === '5') {
          url = `${this.$base}/management/draw/progress`
          this.$axios.post(url, obj).then(res => {
            if (res.data.code === 200) {
              this.$toast.success('提交成功')
              setTimeout(() => {
                this.onRefresh()
              }, 1000)
              // this.$router.go(-1) // 返回上一层
            } else {
              // this.$message.error(res.data.message)
            }
          })
        }
        if (this.tab !== '5') {
          this.$axios.put(url, obj).then(res => {
            if (res.data.code === 200) {
              this.$toast.success('提交成功')
              setTimeout(() => {
                this.onRefresh()
              }, 1000)
              // this.$router.go(-1) // 返回上一层
            } else {
              // this.$message.error(res.data.message)
            }
          })
        }
      } else if (name === '申请') {
        var obj2 = JSON.parse(JSON.stringify(item))
        obj2.progress = 'UNAUDITED'
        obj2.useTime = new Date().getTime()
        let url = ''
        if (this.tab === '3') {
          url = `${this.$base}/management/invoice/record/${item.id}/progress`
        } else if (this.tab === '2') {
          url = `${this.$base}/management/reimbursement/record/${
            item.id
          }/progress?progress=${obj2.progress}`
        } else if (this.tab === '4') {
          url = `${this.$base}/management/borrow/record/${item.id}/progress`
        } else if (this.tab === '5') {
          url = `${this.$base}/management/draw/progress`
          this.$axios.post(url, obj2).then(res => {
            if (res.data.code === 200) {
              this.$toast.success('提交成功')
              setTimeout(() => {
                this.onRefresh()
              }, 1000)
              // this.$router.go(-1) // 返回上一层
            } else {
              // this.$message.error(res.data.message)
            }
          })
        }
        if (this.tab !== '5') {
          this.$axios.put(url, obj2).then(res => {
            if (res.data.code === 200) {
              this.$toast.success('提交成功')
              setTimeout(() => {
                this.onRefresh()
              }, 1000)
              // this.$router.go(-1) // 返回上一层
            } else {
              // this.$message.error(res.data.message)
            }
          })
        }
      } else {
        this.jump(item, index)
      }
    },

    // 跳转事件
    jump(item, index) {
      if (this.tab === '3') {
        this.setFiltersInPage()
        this.$router.push({
          name: 'oa-addInvoice',
          query: {
            commonId: item.id,
            isAdd: 1 // 1表示修改
          }
        })
      } else if (this.tab === '2') {
        this.setFiltersInPage()
        this.$router.push({
          name: 'oa-addReport',
          query: {
            commonId: item.id,
            isAdd: 1 // 1表示修改
          }
        })
      } else if (this.tab === '4') {
        this.setFiltersInPage()
        this.$router.push({
          name: 'oa-addLoan',
          query: {
            commonId: item.id,
            isAdd: 1 // 0表示新增
          }
        })
      } else if (this.tab === '5') {
        this.setFiltersInPage()
        this.$router.push({
          name: 'oa-addIncome',
          query: {
            commonId: item.id,
            isAdd: 1 // 0表示新增
          }
        })
      }
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      // if (!this.organizationId) {
      //   return this.$bus.$emit('join-org', { show: true })
      // }
    },

    // 上拉加载事件
    onLoad() {
      this.page++
      this.loading = true
      if (this.tab === '1') {
        this.getReceiveList()
      } else if (this.tab === '3') {
        this.getInvoice()
      } else if (this.tab === '2') {
        this.getReportList()
      } else if (this.tab === '4') {
        this.getLoanList()
      } else if (this.tab === '5') {
        this.getIncomeList()
      }
    },

    // 下拉刷新
    onRefresh() {
      this.page = 0
      this.isRequest = true
      if (this.tab === '1') {
        this.receiveList = []
        this.getReceiveList()
      } else if (this.tab === '3') {
        this.invoiceList = []
        this.getInvoice()
      } else if (this.tab === '2') {
        this.reportList = []
        this.getReportList()
      } else if (this.tab === '4') {
        this.loanList = []
        this.getLoanList()
      } else if (this.tab === '5') {
        this.incomeList = []
        this.getIncomeList()
      }
    },

    // 搜索功能
    searchInput() {
      this.page = 0
      this.isRequest = true
      if (this.tab === '1') {
        this.receiveList = []
        this.getReceiveList()
      } else if (this.tab === '3') {
        this.invoiceList = []
        this.getInvoice()
      } else if (this.tab === '2') {
        this.reportList = []
        this.getReportList()
      } else if (this.tab === '3') {
        this.loanList = []
        this.getLoanList()
      } else if (this.tab === '5') {
        this.incomeList = []
        this.getIncomeList()
      }
    },

    // 设置查询条件
    setFiltersInPage() {
      this.setFilters({
        filterList: this.filterList
      })
    }
  },
  async created() {
    await this.getId()
    let tab = '1'
    if (this.oaTab) {
      tab = this.oaTab
    } else {
      tab = this.$route.query.tab
    }
    if (tab === '1') {
      this.tab = '1'
      if (this.filterKV) {
        this.filterList = this.filterKV.filterList
      } else {
        this.filterList = this.deepCopy(this.receiveFilterList)
      }
      this.getReceiveList()
    } else if (tab === '2') {
      if (!this.organizationId) {
        return this.$bus.$emit('join-org', { show: true })
      }
      this.tab = '2'
      if (this.filterKV) {
        this.filterList = this.filterKV.filterList
      } else {
        this.filterList = this.deepCopy(this.reportFilterList)
      }
      // this.filterList = this.deepCopy(this.reportFilterList)
      this.getReportList()
    } else if (tab === '3') {
      if (!this.organizationId) {
        return this.$bus.$emit('join-org', { show: true })
      }
      this.tab = '3'
      if (this.filterKV) {
        this.filterList = this.filterKV.filterList
      } else {
        this.filterList = this.deepCopy(this.invoiceFilterList)
      }
      // this.filterList = this.deepCopy(this.invoiceFilterList)
      this.getInvoice()
    } else if (tab === '4') {
      if (!this.organizationId) {
        return this.$bus.$emit('join-org', { show: true })
      }
      this.tab = '4'
      if (this.filterKV) {
        this.filterList = this.filterKV.filterList
      } else {
        this.filterList = this.deepCopy(this.loanFilterList)
      }
      // this.filterList = this.deepCopy(this.loanFilterList)
      this.getLoanList()
    } else if (tab === '5') {
      if (!this.organizationId) {
        return this.$bus.$emit('join-org', { show: true })
      }
      this.tab = '5'
      if (this.filterKV) {
        this.filterList = this.filterKV.filterList
      } else {
        this.filterList = this.deepCopy(this.incomeFilterList)
      }
      // this.filterList = this.deepCopy(this.loanFilterList)
      this.getIncomeList()
    } else {
      this.tab = '1'
      this.filterList = this.deepCopy(this.receiveFilterList)
      this.getReceiveList()
    }
    this.getCategory()
  },
  async mounted() {},
  destroyed() {
    this.setOaTab('')
  }
}
</script>

<style lang="stylus">
.iframe
  width 100%
.filter-right
  width 150px
  height 100px
  background #FFF
  padding 10px 10px
  box-sizing border-box
  font-size 16px
  & .filter-title
    color #999
    width 100%
    text-align center
  & .filter-btn
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    margin-top 80px
    & .btn
      width 40%
      height 25px
      line-height 25px
      font-size 14px
      border-radius 15px
      border none
      color #FFF
      background #4899FC
  & .filter-box
    border-top 1px #CCC solid
    margin-top 20px
    & .van-checkbox
      margin-top 5px
    & .van-radio-group
      & .van-radio
        margin-top 5px
.oa-finance
  position relative
  & .oa-finance-tab
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    height 40px
    padding 0 20px
    box-sizing border-box
    border-bottom 1px solid #cccccc
    & .oa-finance-tab-item
      display flex
      flex-direction row
      align-items center
      span
        font-size 14px
        margin-right 5px
  & .oa-finance-receive-list
    box-sizing border-box
    padding 0 10px
    & .oa-finance-receive
      box-sizing border-box
      padding 10px 0
      border-bottom 1px solid rgba(242, 242, 242, 1)
      & .oa-income
        display flex
        align-items center
        justify-content space-between
        margin-top 10px
        & .oa-income-casename
          font-size 14px
          color #999
          flex 1
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 1
          overflow hidden
        & .oa-case-btn
          display flex
          flex-direction row
          align-items center
          justify-content flex-end
          button
            width 55px
            height 30px
            border-radius 5px
            border 1px solid rgba(0, 121, 254, 1)
            background none
            color #0079FE
            font-size 14px
      & .receive-title
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        font-size 15px
      & .receive-case-name
        margin-top 10px
        div
          color #cccccc
          font-size 14px
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 1
          overflow hidden
  & .oa-case-list
    padding 20px 10px 0 10px
    box-sizing border-box
    min-height 100%
    & .oa-case
      border-radius 5px
      border 1px solid rgba(204, 204, 204, 1)
      width 100%
      height auto
      padding 10px 0
      box-sizing border-box
      margin-bottom 10px
      & .oa-case-header
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        padding 0 10px
        box-sizing border-box
      & .oa-case-title
        font-size 14px
        color #666666
      & .oa-case-money
        font-size 16px
        font-weight 700
      & .oa-case-userInfo
        font-size 14px
        display flex
        flex-direction row
        align-items center
        img
          width 24px
          height 24px
          margin-right 5px
      & .oa-case-status
        font-size 16px
      & .oa-case-category
        display flex
        font-size 14px
        flex-direction row
        align-items center
        justify-content space-between
        padding 10px 10px 0 10px
        box-sizing border-box
      & .oa-case-center
        font-size 16px
        font-weight bold
        box-sizing border-box
        padding 10px 15px
        border-bottom 1px solid rgba(204, 204, 204, 1)
        div
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 2
          overflow hidden
      & .oa-case-bottom
        display flex
        flex-direction row
        align-items center
        box-sizing border-box
        padding 10px 10px 0 10px
        & .oa-case-time
          font-size 12px
          color #999999
          margin-right 10px
        & .oa-case-btn
          display flex
          flex-direction row
          align-items center
          justify-content flex-end
          flex 1
          button
            width 55px
            height 30px
            border-radius 5px
            border 1px solid rgba(0, 121, 254, 1)
            background none
            color #0079FE
            font-size 14px
</style>
