var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.back },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c("div", { attrs: { slot: "right" }, slot: "right" }, [
            _c("div", { staticClass: "header-menu" }, [
              _c(
                "div",
                { staticClass: "icon", on: { click: _vm.onSearch } },
                [_c("van-icon", { attrs: { name: "search" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "icon", on: { click: _vm.onAdd } },
                [_c("van-icon", { attrs: { name: "plus" } })],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      !this.organizationId ? _c("join-organization") : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "right", "get-container": "body" },
          model: {
            value: _vm.filterVisible,
            callback: function ($$v) {
              _vm.filterVisible = $$v
            },
            expression: "filterVisible",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "filter-right",
              style: `height:${_vm.clientHeight}px`,
            },
            [
              _c("div", { staticClass: "filter-title" }, [_vm._v("筛选")]),
              _vm._l(_vm.filterList, function (item, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "filter-box" },
                  [
                    _c(
                      "van-radio-group",
                      {
                        ref: "radioGroup",
                        refInFor: true,
                        model: {
                          value: item.radio,
                          callback: function ($$v) {
                            _vm.$set(item, "radio", $$v)
                          },
                          expression: "item.radio",
                        },
                      },
                      _vm._l(item.options, function (option, index) {
                        return _c(
                          "van-radio",
                          {
                            key: index,
                            ref: "radio",
                            refInFor: true,
                            attrs: { name: option.value, shape: "square" },
                          },
                          [_vm._v(_vm._s(option.text))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "filter-btn" }, [
                _c("button", { staticClass: "btn", on: { click: _vm.reset } }, [
                  _vm._v("重置"),
                ]),
                _c("button", { staticClass: "btn", on: { click: _vm.sure } }, [
                  _vm._v("确定"),
                ]),
              ]),
            ],
            2
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.searchVisible,
            callback: function ($$v) {
              _vm.searchVisible = $$v
            },
            expression: "searchVisible",
          },
        },
        [
          _c("van-field", {
            ref: "inputsearch",
            attrs: { "left-icon": "search", placeholder: "检索案件名称、案号" },
            on: { input: _vm.searchInput },
            model: {
              value: _vm.key,
              callback: function ($$v) {
                _vm.key = $$v
              },
              expression: "key",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "oa-finance" },
        [
          _c(
            "div",
            { staticClass: "oa-finance-tab" },
            _vm._l(_vm.tabList, function (item, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "oa-finance-tab-item",
                  on: {
                    click: function ($event) {
                      return _vm.onTab(item)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      style:
                        item.value === _vm.tab
                          ? "color:#0066FF"
                          : "color:#000000",
                    },
                    [_vm._v(_vm._s(item.text))]
                  ),
                  _c("van-icon", {
                    attrs: {
                      name: "arrow-down",
                      color: "#CCCCCC",
                      size: "14px",
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function ($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing",
              },
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: !_vm.isRequest,
                    "finished-text": "没有更多了",
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _vm.tab === "1"
                    ? _c(
                        "div",
                        { staticClass: "oa-finance-receive-list" },
                        _vm._l(_vm.receiveList, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "oa-finance-receive",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpReceive(item, i)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "receive-title" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(item.stage) +
                                      " " +
                                      _vm._s(item.typeText)
                                  ),
                                ]),
                                _c("div", [_vm._v(_vm._s(item.amount) + "元")]),
                                item.payee === null || item.payee === ""
                                  ? _c("div", [_vm._v(" 收款人：无 ")])
                                  : _c("div", [
                                      _vm._v("收款人：" + _vm._s(item.payee)),
                                    ]),
                              ]),
                              _c("div", { staticClass: "receive-case-name" }, [
                                _c("div", [_vm._v(_vm._s(item.legalCaseName))]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.tab === "2"
                    ? _c(
                        "div",
                        { staticClass: "oa-case-list" },
                        _vm._l(_vm.reportList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "oa-case",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(item, index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "oa-case-header" }, [
                                _c("div", { staticClass: "oa-case-money" }, [
                                  _vm._v("金额：" + _vm._s(item.money) + "元"),
                                ]),
                                _c("div", { staticClass: "oa-case-userInfo" }, [
                                  _c("img", {
                                    attrs: { src: item.avatarUrl, alt: "" },
                                  }),
                                  _c("div", [
                                    _vm._v(_vm._s(item.applicantName)),
                                  ]),
                                ]),
                                item.progress === "UNSUBMENT"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 未提交 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "UNAUDITED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 待审核 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "APPROVED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 审核通过 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "CERTIFIED_FAILED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 审核不通过 ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "oa-case-category" }, [
                                _c("div", [_vm._v(_vm._s(item.reimType))]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.categoryFmt(item.category))
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "oa-case-bottom" }, [
                                _c("div", { staticClass: "oa-case-time" }, [
                                  _vm._v("申请时间：" + _vm._s(item.useTime)),
                                ]),
                                _c("div", { staticClass: "oa-case-long" }, [
                                  _vm._v(_vm._s(item.useTimeText)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "oa-case-btn" },
                                  _vm._l(
                                    item.btnList,
                                    function (btnItem, btnIndex) {
                                      return _c(
                                        "button",
                                        {
                                          key: btnIndex,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.btnClick(
                                                btnItem,
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(btnItem) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.tab === "3"
                    ? _c(
                        "div",
                        { staticClass: "oa-case-list" },
                        _vm._l(_vm.invoiceList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "oa-case",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(item, index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "oa-case-header" }, [
                                _c("div", { staticClass: "oa-case-money" }, [
                                  _vm._v("金额：" + _vm._s(item.money) + "元"),
                                ]),
                                _c("div", { staticClass: "oa-case-userInfo" }, [
                                  _c("img", {
                                    attrs: { src: item.avatarUrl, alt: "" },
                                  }),
                                  _c("div", [
                                    _vm._v(_vm._s(item.applicantName)),
                                  ]),
                                ]),
                                item.progress === "UNSUBMENT"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 未提交 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "UNAUDITED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 待审核 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "APPROVED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 审核通过 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "CERTIFIED_FAILED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 审核不通过 ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "oa-case-center" }, [
                                _c("div", [_vm._v(_vm._s(item.caseName))]),
                              ]),
                              _c("div", { staticClass: "oa-case-bottom" }, [
                                _c("div", { staticClass: "oa-case-time" }, [
                                  _vm._v("申请时间：" + _vm._s(item.useTime)),
                                ]),
                                _c("div", { staticClass: "oa-case-long" }, [
                                  _vm._v(_vm._s(item.useTimeText)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "oa-case-btn" },
                                  _vm._l(
                                    item.btnList,
                                    function (btnItem, btnIndex) {
                                      return _c(
                                        "button",
                                        {
                                          key: btnIndex,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.btnClick(
                                                btnItem,
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(btnItem) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.tab === "4"
                    ? _c(
                        "div",
                        { staticClass: "oa-case-list" },
                        _vm._l(_vm.loanList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "oa-case",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(item, index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "oa-case-header" }, [
                                _c("div", { staticClass: "oa-case-money" }, [
                                  _vm._v(
                                    " 金额：" +
                                      _vm._s(item.amountOfMoney) +
                                      "元 "
                                  ),
                                ]),
                                _c("div", { staticClass: "oa-case-userInfo" }, [
                                  _c("img", {
                                    attrs: { src: item.avatarUrl, alt: "" },
                                  }),
                                  _c("div", [
                                    _vm._v(_vm._s(item.applicantName)),
                                  ]),
                                ]),
                                item.progress === "UNSUBMENT"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 未提交 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "UNAUDITED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 待审核 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "APPROVED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 审核通过 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "CERTIFIED_FAILED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 审核不通过 ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "oa-case-category" }, [
                                _c("div", [
                                  _vm._v("事由：" + _vm._s(item.cause)),
                                ]),
                              ]),
                              _c("div", { staticClass: "oa-case-bottom" }, [
                                _c("div", { staticClass: "oa-case-time" }, [
                                  _vm._v("申请时间：" + _vm._s(item.useTime)),
                                ]),
                                _c("div", { staticClass: "oa-case-long" }, [
                                  _vm._v(_vm._s(item.useTimeText)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "oa-case-btn" },
                                  _vm._l(
                                    item.btnList,
                                    function (btnItem, btnIndex) {
                                      return _c(
                                        "button",
                                        {
                                          key: btnIndex,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.btnClick(
                                                btnItem,
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(btnItem) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.tab === "5"
                    ? _c(
                        "div",
                        { staticClass: "oa-finance-receive-list" },
                        _vm._l(_vm.incomeList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "oa-finance-receive",
                              on: {
                                click: function ($event) {
                                  return _vm.jump(item, index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "receive-title" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(item.applyTime) +
                                      " " +
                                      _vm._s(item.feeType)
                                  ),
                                ]),
                                _c("div", [_vm._v(_vm._s(item.money) + "元")]),
                                item.progress === "UNSUBMENT"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 未提交 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "UNAUDITED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 待审核 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "APPROVED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 审核通过 ")]
                                    )
                                  : _vm._e(),
                                item.progress === "CERTIFIED_FAILED"
                                  ? _c(
                                      "div",
                                      { staticClass: "oa-case-status" },
                                      [_vm._v(" 审核不通过 ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "oa-income" }, [
                                _c(
                                  "div",
                                  { staticClass: "oa-income-casename" },
                                  [_vm._v(_vm._s(item.caseName))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "oa-case-btn" },
                                  _vm._l(
                                    item.btnList,
                                    function (btnItem, btnIndex) {
                                      return _c(
                                        "button",
                                        {
                                          key: btnIndex,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.btnClick(
                                                btnItem,
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(btnItem) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }